'use strict';

var logger = require('../utils/logger.js');
var Q = require('kew');

var config = {
	leadSource: 'parkers',
	localStorageItemName: 'phone-call-tracking-anonymous-id',
	timeoutMilliSecs: 30 * 1000,
	sellerTypes: {
		franchisedDealer: ['Franchised dealers', 'Franchised'],
		independentDealer: ['Independent dealers', 'Independent'],
	}
};

function generateAnonymousId() {
	var result = Math.random().toString(16).slice(2);
	return result;
}

function getAnonymousId() {
	var result = window.localStorage.getItem(config.localStorageItemName);
	if (!result) {
		result = generateAnonymousId();
		window.localStorage.setItem(config.localStorageItemName, result);
	}
	return result;
}

function phoneNumberToInternationalFormat(phoneNumber) {
	var result = phoneNumber;

	if (result) {
		result = result.replace(/\s/g, '');

		if (result.startsWith('00')) {
			result = "+" + result.substring(0, 2);
		}
		else if (!result.startsWith('+')) {
			result = "+44" + result.substring(1);
		}
	}

	return result;
}

function getPhoneNumberFromData(htmlElement, vehicleData, dealerData) {
	return Q.resolve({
		local: dealerData.phoneNumber,
		international: phoneNumberToInternationalFormat(dealerData.phoneNumber)
	});
}

function getPhoneNumberFromKaisa(htmlElement, vehicleData, dealerData) {
	var srcPhoneNumber = dealerData.phoneNumber;
	var advId = dealerData.phoneNumberAdvId;

	var shard = srcPhoneNumber
		.split('')
		.slice(-4)
		.reduce(function (acc, curr) {
			return acc + Number(curr);
		}, 0);

	var parametersMap = {
		'custnr': dealerData.sourceDealerId,
		'custname': dealerData.name,
		'adId': vehicleData.sourceVehicleId,
		'leadSource': config.leadSource,
		'userSessionId': getAnonymousId()
	};

	var parameters = Object.keys(parametersMap)
		.reduce(function (acc, curr) {
			var value = parametersMap[curr];

			if (typeof value !== 'undefined' && value !== null) {
				return acc + '&' + curr + '=' + encodeURIComponent(value);
			} else {
				return acc;
			}
		}, '');

	var getPhoneNumberDeferred = Q.defer();

	window.__fs_dncs_instance.getNumber(
		function (htmlElement, result) {
			getPhoneNumberDeferred.resolve(result);
		},
		htmlElement,
		srcPhoneNumber,
		advId,
		shard,
		parameters,
		null // eventTag
	);

	var result = getPhoneNumberDeferred
		.timeout(config.timeoutMilliSecs, 'phone-call-tracking: timed out ')
		.then(
			function (kaisaData) {
				return {
					local: kaisaData.local,
					international: kaisaData.e164
				};
			},
			function (err) {
				logger.error('phone-call-tracking: ', err);
				return getPhoneNumberFromData(htmlElement, vehicleData, dealerData);
			});

	return result;
}

function getPhoneNumber(htmlElement, vehicleData, dealerData) {
	var result = null;

	if (dealerData.source === 'motors' || config.sellerTypes.independentDealer.includes(dealerData.sellerType)) {
		result = getPhoneNumberFromKaisa(htmlElement, vehicleData, dealerData);
	}

	result = result || getPhoneNumberFromData(htmlElement, vehicleData, dealerData);

	return result;
}

module.exports = {
	getPhoneNumber: getPhoneNumber
};
