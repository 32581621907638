'use strict';

var $ = require('jquery');

var analytics = require('../utils/analytics.js');
var phoneCallTracking = require('../utils/phone-call-tracking.js');

var config = {
	defaultVehicleId: 0,
	delayShowLoadingMilliSecs: 300,
	eventTypes: {
		reveal: 'Reveal',
		call: 'Call'
	},
	vehicleForSaleIdPrefixes: {
		usedCar: 'USEDCFS-',
		usedVan: 'USEDVFS-'
	},
	forSalePrefixes: {
		carsForSale: 'CFS',
		vansForSale: 'VFS'
	}
};

var classNames = {
	contactDealerButton: 'contact-dealer-button',
	contactDealerButtonLoadingModifier: 'loading',
	contactDealerButtonLabelElement: 'label',
};

var dataAttrNames = {
	dealerId: 'data-parkers-dealer-id',
	dealerPhoneNumberReplacement: 'data-parkers-dealer-phone-number-replacement',
	dealerPageType: 'data-parkers-dealer-page-type',
	vehicleId: 'data-parkers-vehicle-id'
};

var gaEventCategories = {
	callCFSDealer: 'Call CFS Dealer',
	callVFSDealer: 'Call VFS Dealer',
	revealCFSDealerPhoneNumber: 'Reveal CFS Dealer Phone Number',
	revealVFSDealerPhoneNumber: 'Reveal VFS Dealer Phone Number'
};

function isCFS(vehicleData) {
	return vehicleData.id.startsWith(config.vehicleForSaleIdPrefixes.usedCar);
}

function isVFS(vehicleData) {
	return vehicleData.id.startsWith(config.vehicleForSaleIdPrefixes.usedVan);
}

function getDealerData($element) {
	var result = null;
	var $hasData = $($element).closest('*[' + dataAttrNames.dealerId + ']');

	if ($hasData && $hasData.length) {
		var dataset = $hasData[0].dataset;
		result = {
			id: dataset.parkersDealerId,
			source: dataset.parkersDealerSource,
			sourceDealerId: dataset.parkersDealerSourceDealerId,
			sellerType: dataset.parkersDealerSellertype,
			name: dataset.parkersDealerName,
			phoneNumber: dataset.parkersDealerPhoneNumber,
			phoneNumberAdvId: dataset.parkersDealerPhoneNumberAdvId
		};
	}

	return result;
}

function getVehicleData($element) {
	var result = null;
	var $hasData = $($element).closest('*[' + dataAttrNames.vehicleId + ']');

	if ($hasData && $hasData.length) {
		var dataset = $hasData[0].dataset;
		result = {
			id: dataset.parkersVehicleId,
			stockId: dataset.parkersVehicleStockId,
			sourceVehicleId: dataset.parkersVehicleSourceVehicleId
		};
	}

	return result;
}

function getGaClickEventArgs(category, vehicleDataId){
	var gaEventArgs = {
		category: category,
		action: 'Click',
		label: vehicleDataId
	};

	return gaEventArgs;
}

function getAnalyticsPhoneNumberEventArgs($element, eventType, vehicleData) {
    var $hasDealerData = $($element).closest('*[' + dataAttrNames.dealerPageType + ']');
    var category = null;
    var vehicleDataId = config.defaultVehicleId;
    var isEventTypeCall = eventType === config.eventTypes.call;
    var isEventTypeReveal = eventType === config.eventTypes.reveal;

    if (vehicleData !== null) {
		var isCarsForSale = isCFS(vehicleData);
		var isVansForSale = isVFS(vehicleData);

        if (isCarsForSale && isEventTypeCall) {
            category = gaEventCategories.callCFSDealer;
            vehicleDataId = vehicleData.stockId;
        } else if (isVansForSale && isEventTypeCall) {
            category = gaEventCategories.callVFSDealer;
            vehicleDataId = vehicleData.id.replace(config.vehicleForSaleIdPrefixes.usedVan, '');
        } else if (isCarsForSale && isEventTypeReveal) {
            category = gaEventCategories.revealCFSDealerPhoneNumber;
            vehicleDataId = vehicleData.stockId;
        } else if (isVansForSale && isEventTypeReveal) {
            category = gaEventCategories.revealVFSDealerPhoneNumber;
            vehicleDataId = vehicleData.id.replace(config.vehicleForSaleIdPrefixes.usedVan, '');
        }
    } else if ($hasDealerData && $hasDealerData.length > 0) {
        var dealerDataset = $hasDealerData[0].dataset;
        var dealerPageType = dealerDataset.parkersDealerPageType;
        var isDealerCarsForSale = dealerPageType === config.forSalePrefixes.carsForSale;
        var isDealerVansForSale = dealerPageType === config.forSalePrefixes.vansForSale;

        if (isDealerCarsForSale && isEventTypeCall) {
            category = gaEventCategories.callCFSDealer;
        } else if (isDealerVansForSale && isEventTypeCall) {
            category = gaEventCategories.callVFSDealer;
        }  else if (isDealerCarsForSale && isEventTypeReveal) {
            category = gaEventCategories.revealCFSDealerPhoneNumber;
        } else if (isDealerVansForSale && isEventTypeReveal) {
            category = gaEventCategories.revealVFSDealerPhoneNumber;
        }
    }

    var result = getGaClickEventArgs(category, vehicleDataId);
    return result;
}

function trackAnalyticsPhoneNumberEvent($element, eventType, vehicleData) {
	var gaEventArgs = getAnalyticsPhoneNumberEventArgs($element, eventType, vehicleData);
	if (gaEventArgs !== null) {
		analytics.trackEvent(gaEventArgs.category, gaEventArgs.action, gaEventArgs.label);
	}
}

function handlePhoneNumberClick(e) {
	var $phoneNumber = $(e.target);
	var vehicleData = getVehicleData($phoneNumber);

	trackAnalyticsPhoneNumberEvent($phoneNumber, config.eventTypes.call, vehicleData);
}

function handle_phoneNumberReplacementRequired_click(e) {
	e.preventDefault();

	var $phoneNumber = $(e.target);
	var vehicleData = getVehicleData($phoneNumber);

	var dealerData = getDealerData($phoneNumber);
	if (!dealerData) {
		return;
	}

	trackAnalyticsPhoneNumberEvent($phoneNumber, config.eventTypes.reveal, vehicleData);

	var $matchingPhoneNumbers = [];

	if (vehicleData !== null) {
		$matchingPhoneNumbers =  $('*[' + dataAttrNames.dealerPhoneNumberReplacement + '="required"]').filter(function (index, $element) {
			var elementVehicleData = getVehicleData($element);
			return elementVehicleData && elementVehicleData.id === vehicleData.id;
		});
	}

	var showLoadingTimeout = setTimeout(function () {
		$matchingPhoneNumbers
			.off('click')
			.on('click', function (e) { e.preventDefault(); return false; })
			.addBemClass(classNames.contactDealerButton, null, classNames.contactDealerButtonLoadingModifier);
	}, config.delayShowLoadingMilliSecs);

	phoneCallTracking
		.getPhoneNumber(
			e.target,
			vehicleData,
			dealerData
		)
		.then(function (phoneNumberData) {
			clearTimeout(showLoadingTimeout);

			$matchingPhoneNumbers
				.removeBemClass(classNames.contactDealerButton, null, classNames.contactDealerButtonLoadingModifier)
				.attr('href', 'tel:' + phoneNumberData.international)
				.attr(dataAttrNames.dealerPhoneNumberReplacement, 'succeeded')
				.off('click')
				.on('click', handlePhoneNumberClick);

			$matchingPhoneNumbers
				.childrenByBem(classNames.contactDealerButton, classNames.contactDealerButtonLabelElement)
				.html(phoneNumberData.local);
		});

	return false;
}

function phoneNumberReplacementInit() {
	var $phoneNumbersToReplace = $('*[' + dataAttrNames.dealerPhoneNumberReplacement + '="required"]');

	$phoneNumbersToReplace.each(function () {
		$(this)
			.off('click')
			.on('click', handle_phoneNumberReplacementRequired_click);
	});
}

module.exports = {
	init: function () {
		phoneNumberReplacementInit();
	}
};